// common
import Dialogue from "@/components/common/dialogue_client.vue"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import '@/assets/scss/vendors/vue2-datepicker.scss'

export default {
    props: ['id', 'type', 'listItem', 'salesRepList'],
    components: {
        Dialogue,
        DatePicker
    },
    data: function() {
        return {
            // 取得帳號清單
            users: [],
            // 匯入帳號資訊
            importUserInfo: {
                path: '',
                name: '',
                file: ''
            },
            uploadErrorMsg: {
                file: null,
            },

            // 下拉選單
            counties: [],
            districts: [],
            schoolList: [],
            courseList: [],
            generalCourses: [],
            idealCourses: [],
            issueList: [],
            collaborativeContractList: '',
            // 訂單資料
            schoolInfo: {
                id:'',
                inputBaanNo: '',
                selectedCounty: '',
                selectedDistrict: '',
                selectedEdu: '',
                isWholeSchool: true,
                selectedSchoolId: '',
                completeSchoolName: '',
                selectedSchoolName: '',
                inputImportCount: 0,
                isIdealiCourse: false,
                selectedCourse: '',
                selectedCourseList: [],
                selectedCourseIds: [],
                selectedCertificationDate: '',
                selectedActivityDate: '',
                selectedDuration: '',
                selectedStartIssueNo: '',
                inputIssueCount: '',
                selectedStartDateTime: '',
                selectedEndDateTime: '',
                inputBuyerEmail: '',
                selectedSalesRepId: '0',
                selectedCollaborativeContract: '',
                inputDescription: '',
                inputNote: '',
            },
            oldSchoolInfo: {
                selectedCourseIds: []
            },
            isAutoType: false,
            durationTextList: ['一', '二', '三', '四', '五'],

            isPostingApi: {
                adding: false,  // 新增學校訂單
                accountUploading: false, // 匯入帳號
                editing: false,          // 重新編輯資料
            }
        }
    },
    watch: {
        'schoolInfo.selectedStartDateTime'(newvalue) {
            if (newvalue &&
                this.schoolInfo.selectedStartDateTime != this.oldSchoolInfo.selectedStartDateTime) { // 與oldSchoolInfo值比對不同才能重新設定
                this.resetDate(newvalue);
            }
        }
    },
    computed: {
        layerList() {
            let list = [];
            if (this.schoolInfo.isWholeSchool || this.schoolInfo.isWholeCounty) {
                list = [
                    '國小',
                    '國中',
                    '高中高職',
                    '大專院校'
                ];
            }
            else {
                list = [
                    '國小',
                    '國中',
                    '高中高職',
                    '大專院校',
                    '其他'
                ];
            }
            return list;
        },
        durationList() {
            let inputArr = [];
            inputArr = [
                {
                    value: 1,
                    name: '一年'
                },
                {
                    value: 2,
                    name: '二年'
                },
                {
                    value: 3,
                    name: '三年'
                },
                {
                    value: 4,
                    name: '四年'
                },
                {
                    value: 5,
                    name: '五年'
                },
                {
                    value: 6,
                    name: '六年'
                },
                {
                    value: 7,
                    name: '七年'
                },
                {
                    value: 8,
                    name: '八年'
                },
                {
                    value: 9,
                    name: '九年'
                },
                {
                    value: 10,
                    name: '十年'
                }
            ]

            return inputArr;
        },
        countyDistrict: {
            get() {
                return this.$store.state.adminSchoolBuying.countyDistrict;
            },
            set(value) {
                this.$store.commit('adminSchoolBuying/setCountyDistrict', value);
            }
        },
        updateSchoolInfoData() {
            let updateData = {};
            let excludeKeys = ['id', 'selectedCourse', 'selectedCourseList', 'selectedCourseIds'];  // 排除檔案
            updateData = this.$_.omitBy(this.$_.omit(this.schoolInfo, excludeKeys), (v, k) => {
                return this.oldSchoolInfo[k] === v;
            });

            if (!this.$_.isEqual(this.oldSchoolInfo.selectedCourseIds, this.schoolInfo.selectedCourseIds)) {
                updateData.selectedCourseIds = this.schoolInfo.selectedCourseIds;
            }

            return updateData;
        },
        isSchoolBuyingDisable() { // 檢查訂單參數是否有沒填的
            if (!this.schoolInfo.inputBaanNo ||
                !this.schoolInfo.selectedCounty ||
                this.schoolInfo.selectedCourseIds.length == 0 ||
                (this.type == 'edit' && this.$_.isEmpty(this.updateSchoolInfoData)) ||
                !this.isEmailInputValid(this.schoolInfo.inputBuyerEmail)) {
                    return true;
                }
            return false;
        },
        isEmailInputValid() {
            let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // email格式
            // 不是email格式則為 false
            return value => !value || regExp.test(value);  // 排除 value 為空的情況
        }
    },
    methods: {
        dialougeLoadedHandler() {
            // 一開啟彈窗時
            $(`#${this.id}`).on('show.bs.modal', () => {
                this.initialize();
            });
        },
        async initialize() {
            this.counties = this.countyDistrict.counties;
            this.getCollaborativeContractList();
            this.schoolList = [];
            this.courseList = [];
            this.generalCourses = [];
            this.idealCourses = [];
            await this.getCourseList();
            if (this.type == 'add') {
                this.addingInfo();
            }
            if (this.type == 'edit') {
                this.editingInfo();
            }
        },
        async getCollaborativeContractList() {
            await $.getJSON('https://premium-resource.learnmode.net/public/order/collaborative_contract.json', (data) => {
                this.collaborativeContractList = data;
            });
        },
        getCourseList() {
            this.$httpRequest.get('/admin_api/course/get_courses_and_groups')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            if (result.group) {
                                for (let tempCourse of result.group) {
                                    this.generalCourses.push({
                                        id: tempCourse.course_id != 0 ? tempCourse.course_id : tempCourse.course_group_id != 0 ? tempCourse.course_group_id : 0,
                                        isCourseGroup: tempCourse.course_group_id != 0 ? true : false,
                                        name: tempCourse.name
                                    })
                                }
                            }
                            if (result.single) {
                                for (let tempCourse of result.single) {
                                    this.generalCourses.push({
                                        id: tempCourse.course_id != 0 ? tempCourse.course_id : tempCourse.course_group_id != 0 ? tempCourse.course_group_id : 0,
                                        isCourseGroup: tempCourse.course_group_id != 0 ? true : false,
                                        name: tempCourse.name
                                    })
                                }
                            }
                            if (result.ideali) {
                                for (let tempCourse of result.ideali) {
                                    this.idealCourses.push({
                                        id: tempCourse.course_id != 0 ? tempCourse.course_id : tempCourse.course_group_id != 0 ? tempCourse.course_group_id : 0,
                                        isCourseGroup: tempCourse.course_group_id != 0 ? true : false,
                                        name: tempCourse.name
                                    })
                                }
                            }

                            this.courseList = this.generalCourses;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        addingInfo() {
            // 初始化參數
            this.schoolInfo = {
                id: '',
                inputBaanNo: '',
                selectedCounty: '',
                isWholeCounty: false,
                selectedDistrict: '',
                selectedEdu: '',
                isWholeSchool: true,
                selectedSchoolId: '',
                completeSchoolName: '',
                selectedSchoolName: '',
                inputImportCount: 0,
                isIdealiCourse: false,
                selectedCourse: '',
                selectedCourseList: [],
                selectedCourseIds: [],
                selectedActivityDate: '',
                selectedDuration: '',
                selectedStartIssueNo: '',
                inputIssueCount: '',
                selectedStartDateTime: '',
                selectedEndDateTime: '',
                inputBuyerEmail: '',
                selectedSalesRepId: '0',
                selectedCollaborativeContract: '',
                inputDescription: '',
                inputNote: '',
            }
            this.isAutoType = false;
        },
        editingInfo() {
            this.schoolInfo.id = this.listItem.id;
            this.schoolInfo.inputBaanNo = this.listItem.inputBaanNo;
            this.schoolInfo.isWholeCounty = this.listItem.isWholeCounty;
            this.schoolInfo.selectedCounty = this.listItem.selectedCounty;
            this.schoolInfo.selectedEdu = this.listItem.selectedEdu;
            this.schoolInfo.isWholeSchool = this.listItem.isWholeSchool;
            this.schoolInfo.selectedSchoolId = this.listItem.selectedSchoolId;
            this.schoolInfo.completeSchoolName = this.listItem.isWholeCounty ? this.listItem.selectedCounty + this.listItem.selectedSchoolName : this.listItem.selectedCounty + this.listItem.selectedDistrict + this.listItem.selectedSchoolName
            this.schoolInfo.selectedSchoolName = this.listItem.selectedSchoolName;
            this.schoolInfo.inputImportCount = this.listItem.inputImportCount;
            this.schoolInfo.isIdealiCourse = this.listItem.isIdealiCourse; // 因為不會顯示，暫時用不到
            this.schoolInfo.selectedCourseIds = this.listItem.selectedCourseIds;
            this.schoolInfo.selectedCourseList = this.listItem.selectedCourseList;
            this.schoolInfo.selectedCertificationDate = this.listItem.selectedCertificationDate;
            this.schoolInfo.selectedActivityDate = this.listItem.selectedActivityDate;
            this.schoolInfo.selectedDuration = this.listItem.selectedDuration;
            this.schoolInfo.selectedStartIssueNo = this.listItem.selectedStartIssueNo;
            this.schoolInfo.inputIssueCount = this.listItem.inputIssueCount;
            this.schoolInfo.selectedStartDateTime = this.listItem.selectedStartDateTime;
            this.schoolInfo.selectedEndDateTime = this.listItem.selectedEndDateTime;
            this.schoolInfo.inputBuyerEmail = this.listItem.inputBuyerEmail;
            this.schoolInfo.selectedSalesRepId = this.listItem.selectedSalesRepId;
            this.schoolInfo.selectedCollaborativeContract = this.listItem.selectedCollaborativeContract;
            this.schoolInfo.inputDescription = this.listItem.inputDescription;
            this.schoolInfo.inputNote = this.listItem.inputNote;

            if (this.schoolInfo.selectedCounty) {
                this.getDistrict();
                this.schoolInfo.selectedDistrict = this.listItem.selectedDistrict;
            }

            if (this.schoolInfo.selectedCourseList[0].isCourseGroup) {
                this.getIssueNumberList(this.schoolInfo.selectedCourseList[0].id);
            }

            this.oldSchoolInfo = this.$_.cloneDeep(this.schoolInfo);
        },
        getDistrict(){
            this.schoolInfo.selectedDistrict = '';
            this.districts = [];
            this.districts = this.countyDistrict.districts[this.schoolInfo.selectedCounty];
        },
        getSchoolList(isDistrict) {
            if (isDistrict && !this.schoolInfo.selectedEdu) {
                return;
            }
            if (this.schoolInfo.selectedEdu == '其他') {
                this.schoolInfo.selectedSchoolId = 0;
            }
            else {
                this.schoolInfo.completeSchoolName = '';
                this.schoolInfo.selectedSchoolName = '';
            }

            let params = {
                county: this.schoolInfo.selectedCounty,
                district: this.schoolInfo.selectedDistrict,
                edu: this.schoolInfo.selectedEdu
            }

            this.$httpRequest.get('/api/user/get_school_list', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.schoolList = result;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        chooseSchool(item) {
            this.schoolList = [];
            this.schoolInfo.selectedSchoolId = item.school_id;
            this.schoolInfo.completeSchoolName = this.schoolInfo.selectedCounty + this.schoolInfo.selectedDistrict + item.name;
            this.schoolInfo.selectedSchoolName = this.schoolInfo.selectedCounty + this.schoolInfo.selectedDistrict + item.name;
        },
        isResetVariable(isAdd) {
            // 如果是全縣訂購，要把地區、學程、學校、全校訂購、帳號數量清空
            if (this.schoolInfo.isWholeCounty) {
                this.schoolInfo.selectedDistrict = '';
                this.schoolInfo.isWholeSchool = false;
                this.schoolInfo.selectedSchoolId = 0;
                this.schoolInfo.completeSchoolName = '';
                this.schoolInfo.selectedSchoolName = '';
                this.schoolInfo.inputImportCount = 0;
                this.schoolInfo.isIdealiCourse = false;
                this.isReloadCourseList(isAdd);
            }
        },
        checkEduStatus() {
            if (this.schoolInfo.isWholeSchool && this.schoolInfo.selectedEdu == '其他') {
                this.schoolInfo.selectedEdu = '';
                this.schoolInfo.completeSchoolName = '';
                this.schoolInfo.selectedSchoolName = '';
            }
        },
        checkIsNumber(num) { // 檢查是否有數字以外的字元，有的話會直接無法輸入
            return num.replace(/[^0-9]/g, "");
        },
        isReloadCourseList(isAdd) {
            if (!isAdd) {
                this.schoolInfo.selectedCourseList = [];
            }
            this.courseList = this.schoolInfo.isIdealiCourse ? this.idealCourses : this.generalCourses;
        },
        chooseCourse() {
            // selectedCourseList陣列裡面期數型的課程僅只能有一個
            if (this.schoolInfo.selectedCourseList.length > 0) {
                if (this.schoolInfo.selectedCourseList[0].isCourseGroup ||
                    this.schoolInfo.selectedCourse.isCourseGroup) {
                    this.$store.dispatch('common/setAlert', { msg: '不能選取一個以上的期數型課程，請刪掉再重新選取', status: 'danger' });
                    this.schoolInfo.selectedCourse = '';
                    return;
                }
            }
            if (this.schoolInfo.selectedCourse.isCourseGroup) {
                this.getIssueNumberList(this.schoolInfo.selectedCourse.id);
            }
            if (!this.$_.find( this.schoolInfo.selectedCourseList, this.schoolInfo.selectedCourse )) {
                this.schoolInfo.selectedCourseList.push(this.schoolInfo.selectedCourse);
            }
            if (!this.$_.find( this.schoolInfo.selectedCourseIds, this.schoolInfo.selectedCourse.id )) {
                this.schoolInfo.selectedCourseIds.push(this.schoolInfo.selectedCourse.id);
            }
            this.schoolInfo.selectedCourse = '';
        },
        getIssueNumberList(id) {
            this.issueList = [];
            let params = {
                courseGroupId: id
            }
            this.$httpRequest.get('/admin_api/course/get_issue_no_by_group_id', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            for(let el of result) {
                                this.issueList.push({
                                    no: el.issue_no,
                                    date: el.issue_no_date ? el.issue_no_date.split(" ")[0] : el.issue_no
                                })
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        resetDate(date) {
            if (!date) {
                return;
            }

            if (this.schoolInfo.selectedDuration > 0) {

                let inputDate = new Date(date);
                let nowYear = inputDate.getFullYear();
                this.schoolInfo.selectedEndDateTime = date.replace(nowYear, parseInt(nowYear) + parseInt(this.schoolInfo.selectedDuration));
            }
        },
        autoTypeDescr() {
            if (!this.isAutoType) {
                this.schoolInfo.inputDescription = '';
                return;
            }

            this.schoolInfo.inputDescription = this.schoolInfo.selectedCollaborativeContract.description

            // let courseName = this.schoolInfo.selectedCollaborativeContract;

            // if (this.type == 'edit') {
            //     this.schoolInfo.inputDescription = this.schoolInfo.selectedCounty + this.schoolInfo.selectedDistrict + this.schoolInfo.selectedSchoolName + '「' + courseName + '」';
            // }
            // else {
            //     this.schoolInfo.inputDescription = this.schoolInfo.isWholeCounty ? this.schoolInfo.selectedCounty + this.schoolInfo.selectedSchoolName + '「' + courseName + '」' : this.schoolInfo.selectedSchoolName + '「' + courseName + '」';
            // }
            // if (this.schoolInfo.isWholeSchool &&
            //     this.schoolInfo.selectedSchoolName &&
            //     courseName) {
            //     this.schoolInfo.inputDescription = this.schoolInfo.inputDescription + '全校師生';
            // }
            // if (!this.schoolInfo.isWholeSchool &&
            //     this.schoolInfo.selectedSchoolName &&
            //     courseName) {
            //     this.schoolInfo.inputDescription = this.schoolInfo.inputDescription + '全校' + this.schoolInfo.inputImportCount + '組帳號';
            // }

            // if (courseName && this.schoolInfo.selectedCourseList && this.schoolInfo.selectedCourseList[0].isCourseGroup) {
            //     if (this.schoolInfo.inputIssueCount) {
            //         this.schoolInfo.inputDescription = this.schoolInfo.inputDescription + this.schoolInfo.inputIssueCount + '期授權';
            //     }
            // }
            // else {
            //     if (this.schoolInfo.selectedDuration > 0) {
            //         this.schoolInfo.inputDescription = this.schoolInfo.inputDescription + '授權' + this.durationTextList[this.schoolInfo.selectedDuration-1] + '年使用';
            //     }
            // }
        },
        deleteCourse(id) {
            let length = this.schoolInfo.selectedCourseList.length -1
            for(let i = length; i >=0; i--) {
                if (this.schoolInfo.selectedCourseList[i].id == id) {
                    this.schoolInfo.selectedCourseList.splice(i, 1);
                }
                if (this.schoolInfo.selectedCourseIds[i] == id) {
                    this.schoolInfo.selectedCourseIds.splice(i, 1);
                }
            }
        },
        addSchoolBuying() {
            if (this.isAddSchoolBuyingDisable || this.isPostingApi.adding) {
                return;
            }

            this.isResetVariable(true);

            let info = {
                orderNo: this.schoolInfo.inputBaanNo,
                county: this.schoolInfo.selectedCounty,
            }

            if (this.schoolInfo.selectedDistrict) {
                info.district = this.schoolInfo.selectedDistrict;
            }

            if (this.schoolInfo.selectedEdu) {
                info.edu = this.schoolInfo.selectedEdu;
            }

            if (!this.schoolInfo.isWholeCounty) {
                info.isFullSchool = this.schoolInfo.isWholeSchool;
            }
            else {
                if (this.schoolInfo.selectedEdu) {
                    info.countyGrade = this.schoolInfo.selectedEdu;
                }
            }

            if (this.schoolInfo.selectedSchoolId != 0) {
                if (this.schoolInfo.selectedSchoolId) {
                    info.schoolId = this.schoolInfo.selectedSchoolId;
                }
            }
            else {
                if (this.schoolInfo.selectedSchoolName) {
                    info.schoolName = this.schoolInfo.selectedSchoolName;
                }
            }

            if (this.schoolInfo.inputImportCount != '') {
                info.importCount = this.schoolInfo.inputImportCount;
            }

            if (this.schoolInfo.selectedCertificationDate) {
                info.certDate = this.$util.datetimeToUnixTimestamp(this.schoolInfo.selectedCertificationDate);
            }

            if (this.schoolInfo.selectedActivityDate) {
                info.startDate = this.$util.datetimeToUnixTimestamp(this.schoolInfo.selectedActivityDate);
            }

            if (this.schoolInfo.selectedCourseList.length > 0) {
                // 如果是期數型課程，courseGroupId只允許一個
                if (this.schoolInfo.selectedCourseList[0].isCourseGroup) {
                    info.courseGroupId = this.schoolInfo.selectedCourseIds[0];
                    if (this.schoolInfo.selectedStartIssueNo) {
                        info.start = this.schoolInfo.selectedStartIssueNo;
                    }
                    if (this.schoolInfo.inputIssueCount) {
                        info.end = parseInt(this.schoolInfo.inputIssueCount);
                    }
                }
                else {
                    info.courseIds = this.schoolInfo.selectedCourseIds;
                    if (this.schoolInfo.selectedStartDateTime) {
                        info.start = this.$util.datetimeToUnixTimestamp(this.schoolInfo.selectedStartDateTime);
                    }
                    if (this.schoolInfo.selectedEndDateTime) {
                        info.end = this.$util.datetimeToUnixTimestamp(this.schoolInfo.selectedEndDateTime);
                    }
                    if (this.schoolInfo.selectedDuration > 0) {
                        info.duration = this.schoolInfo.selectedDuration;
                    }
                }
            }

            info.type = this.schoolInfo.isIdealiCourse ? 'ideali' : 'general';

            if (this.schoolInfo.inputBuyerEmail) {
                info.email = this.schoolInfo.inputBuyerEmail;
            }

            if (this.schoolInfo.selectedSalesRepId) { // 如果0不會進入，但後端會給0
                info.salesRepId = this.schoolInfo.selectedSalesRepId;
            }

            if (this.schoolInfo.selectedCollaborativeContract.name) {
                info.itemName = this.schoolInfo.selectedCollaborativeContract.name;
            }

            if (this.schoolInfo.inputDescription) {
                info.description = this.schoolInfo.inputDescription;
            }

            if (this.schoolInfo.inputNote) {
                info.note = this.schoolInfo.inputNote;
            }

            let params = {
                orderInfo: info
            }

            this.isPostingApi.adding = true;

            this.$httpRequest.post('/admin_api/order/create_school_order', params)
                .then(response => {
                    this.isPostingApi.adding = false;
                    if (response.data.state == 'OK') {
                        let result = response.data.result;
                        if (result) {
                            $('#schoolBuyingAddingDialogue').modal('hide');
                            this.$parent.getSchoolBuyingIdsByState();
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        confirmEditing() {
            if (this.isPostingApi.editing) {
                return;
            }

            let info = this.updateSchoolInfoData,
                params = {
                oslId : this.schoolInfo.id,
                orderInfo : {}
            }

            if (info.inputBaanNo) {
                params.orderInfo.orderNo = info.inputBaanNo;
            }
            if (info.inputImportCount != '') {
                params.orderInfo.importCount = info.inputImportCount;
            }
            if (info.selectedCertificationDate || info.selectedCertificationDate === null) {
                params.orderInfo.certDate = info.selectedCertificationDate ? this.$util.datetimeToUnixTimestamp(info.selectedCertificationDate) : '';
            }
            if (info.selectedActivityDate || info.selectedActivityDate === null) {
                params.orderInfo.startDate = info.selectedActivityDate ? this.$util.datetimeToUnixTimestamp(info.selectedActivityDate) : '';
            }
            if (info.selectedStartIssueNo) {
                params.orderInfo.start = info.selectedStartIssueNo;
                params.orderInfo.end = this.schoolInfo.inputIssueCount; // 因為改一個另一個也要帶過去
            }
            if (info.inputIssueCount || info.inputIssueCount == '') {
                params.orderInfo.end = info.inputIssueCount ? parseInt(info.inputIssueCount) : '';
                params.orderInfo.start = this.schoolInfo.selectedStartIssueNo;
            }
            if (info.selectedStartDateTime || info.selectedStartDateTime === null) {
                params.orderInfo.start = info.selectedStartDateTime ? this.$util.datetimeToUnixTimestamp(info.selectedStartDateTime) : '';
                params.orderInfo.end = this.$util.datetimeToUnixTimestamp(this.schoolInfo.selectedEndDateTime);
            }
            if (info.selectedEndDateTime || info.selectedEndDateTime === null) {
                params.orderInfo.end = info.selectedEndDateTime ? this.$util.datetimeToUnixTimestamp(info.selectedEndDateTime) : '';
                params.orderInfo.start = this.$util.datetimeToUnixTimestamp(this.schoolInfo.selectedStartDateTime);
            }
            if (info.selectedDuration) {
                params.orderInfo.duration = info.selectedDuration;
            }
            if (info.inputBuyerEmail) {
                params.orderInfo.email = info.inputBuyerEmail;
            }
            if (info.selectedSalesRepId) { // 如果0不會進入，但後端會給0
                params.orderInfo.salesRepId = info.selectedSalesRepId;
            }
            if (info.selectedCollaborativeContract.name) {
                params.orderInfo.itemName = info.selectedCollaborativeContract.name;
            }
            if (info.inputDescription) {
                params.orderInfo.description = info.inputDescription;
            }
            if (info.inputNote) {
                params.orderInfo.note = info.inputNote;
            }

            this.isPostingApi.editing = true;
            this.$httpRequest.post('/admin_api/order/update_school_order', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$parent.$parent.getSchoolBuyingIdsByState();
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        $('#schoolBuyingEditingDialogue').modal('hide');
                    }
                    this.isPostingApi.editing = false;
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                    this.isPostingApi.editing = false;
                    $('#schoolBuyingEditingDialogue').modal('hide');
                });
        },

        disablePickerActiviteDate(date) {
            // disable 小於今天以前的日期
            return (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0));
        },
        disablePickerStartDate(date) {
            // disable 小於今天以前的日期 & 大於有設定的結束日期
            return (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ||
                (this.schoolInfo.selectedEndDateTime && date.setHours(0, 0, 0, 0) > new Date(this.schoolInfo.selectedEndDateTime).setHours(0, 0, 0, 0));
        },
        disablePickerEndDate(date) {
            // disable 小於今天以前的日期 & 小於有設定的開始日期
            return (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ||
                (this.schoolInfo.selectedStartDateTime && date.setHours(0, 0, 0, 0) < new Date(this.schoolInfo.selectedStartDateTime).setHours(0, 0, 0, 0));
        },
    }
}